<template>
  <layout>
    <template v-slot:header-title>
      {{ pageTitle }}
    </template>
    <template v-if="bike !== null" v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__content mb-32">
            <div class="main__content-headline">
              <h5 class="mb-1">Main info</h5>
              <div>
                <div class="main__content tab-pane">
                  <div class="main__content-in">
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">Build:</div>
                      <div class="main__content-item_r">
                        <router-link
                          v-if="bike !== null"
                          :to="buildLink"
                          class="color__black"
                        >
                          {{ buildTitle }}
                        </router-link>
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">Is testing:</div>
                      <div class="main__content-item_r">
                        <template v-if="bike.is_testing">Yes</template>
                        <template v-else>No</template>
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">Serial number:</div>
                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="serialNumber"
                            :disabled="!edit"
                            title="Serial number"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        Frame #:
                      </div>
                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="frameNumber"
                            :disabled="!edit"
                            title="Frame"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">GPS Antenna Type:</div>
                      <div class="main__content-item_r">
                        <div class="inp-group__dropdown inp-group__xl">
                          <v-select
                            v-model="GPSAntennaType"
                            :disabled="!edit"
                            :options="GPSAntennaTypes"
                            label="label"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">Brakes:</div>
                      <div class="main__content-item_r">
                        <div class="inp-group__dropdown inp-group__xl">
                          <v-select
                            v-model="brakeType"
                            :disabled="!edit"
                            :options="brakeTypes"
                            label="label"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">Speed limit:</div>
                      <div class="main__content-item_r">
                        <div class="inp-group__dropdown inp-group__xl">
                          <v-select
                            v-model="speedLimit"
                            :disabled="!edit"
                            :options="speedLimits"
                            label="label"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        Manufacturing date:
                      </div>
                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <date-picker
                            v-model="manufacturingDate"
                            :disabled="!edit"
                            :format="'DD.MM.YYYY'"
                            value-type="date"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">SIM-card number:</div>
                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="simCardNumber"
                            :disabled="!edit"
                            title="Sim card number"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">App status:</div>
                      <div class="main__content-item_r">
                        <div class="inp-group__dropdown inp-group__xl">
                          <v-select
                            v-model="status"
                            :disabled="!edit"
                            :options="bikeStatuses"
                            label="label"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">Main status:</div>
                      <div class="main__content-item_r">
                        <div class="inp-group__dropdown inp-group__xl">
                          <v-select
                            v-model="main_status"
                            :disabled="!edit"
                            :options="bikeMainStatuses"
                            label="label"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">Activation date:</div>
                      <div class="main__content-item_r">
                        {{ activatedDate }}
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">Mileage:</div>
                      <div class="main__content-item_r">
                        {{ lastMileageData }}
                        <a
                          v-if="firstMileage"
                          class="link"
                          @click="mileageOpen = !mileageOpen"
                        >
                          details
                        </a>
                      </div>
                    </div>
                    <hr class="w-pr" />
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">Current owner:</div>
                      <div class="main__content-item_r">
                        <div class="inp-group__dropdown inp-group__xl">
                          <v-select
                            v-model="owner"
                            :disabled="!edit"
                            :options="userList"
                            label="label"
                            @search="onSearch"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        User's custom bike name:
                      </div>
                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="customTitle"
                            :disabled="!edit"
                            title="User's custom bike name"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">Purchase date:</div>
                      <div class="main__content-item_r">
                        {{ purchaseDate }}
                      </div>
                    </div>
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">Warranty type:</div>
                      <div class="main__content-item_r">
                        {{ bikeWarranty }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <build-read-only v-if="showBikeReadOnly" />

          <div class="main__content-btns">
            <button class="show-btn" @click="toggleBuildInfo">
              {{ showBuildInfoBtnText }}
            </button>
          </div>

          <feed
            v-if="bike && bike.id"
            :subject-id="bike ? bike.id : null"
            subject="bike"
          />

          <div v-if="!isRepairTab" class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="saveOrEdit">
              <span>
                <i>{{ saveBtnText }}</i>
              </span>
            </button>
            <button class="btn btn-big btn-fw" @click.prevent="closeOrNotEdit">
              <span>
                <i>
                  {{ closeOrCancel }}
                </i>
              </span>
            </button>
          </div>

          <div v-if="isRepairTab" class="main__content-btns">
            <button
              v-if="repair !== null"
              class="btn btn-big btn-fw"
              @click.prevent="saveRepair"
            >
              <span>
                <i>Save</i>
              </span>
            </button>
            <button
              v-if="repair !== null"
              class="btn btn-big btn-fw"
              @click.prevent="deleteRepair"
            >
              <span>
                <i>
                  Cancel
                </i>
              </span>
            </button>
          </div>

          <div v-if="mileageOpen" class="modalMileage">
            <button class="close" @click="mileageOpen = false">x</button>
            <div class="modal-container">
              <p class="title">
                Mileage:
                <strong>{{
                  formatMileageDistance(lastMileage.distance)
                }}</strong>
                m (
                <strong>{{ lastMileage.time }}</strong>
                h)
              </p>
              <div class="wrapper-month">
                <div
                  v-for="(date, index) in mileageGroupKeys"
                  :key="index"
                  class="month-item"
                >
                  <p class="month-name">{{ date }}</p>
                  <ul class="list">
                    <li v-for="item in mileage(date)" :key="item.id">
                      <p :class="mileageClass(item)" class="list-l">
                        {{ formatMileageDistance(item.distance) }} ({{
                          item.time
                        }})
                      </p>
                      <p class="list-r">{{ item.created_at }}</p>
                    </li>
                    <li v-if="index === latestMileageGroup && firstMileage">
                      <p class="list-r">...</p>
                    </li>
                    <li v-if="index === latestMileageGroup && firstMileage">
                      <p class="list-l">
                        {{ formatMileageDistance(firstMileage.distance) }} ({{
                          firstMileage.time
                        }})
                      </p>
                      <p class="list-r">{{ firstMileage.created_at }}</p>
                    </li>
                  </ul>
                </div>
                <div
                  v-if="mileageGroupKeys.length === 0 && firstMileage !== null"
                  class="month-item"
                >
                  <p class="month-name">
                    {{ firstMileage.month }} {{ firstMileage.year }}
                  </p>
                  <ul class="list">
                    <li>
                      <p :class="mileageClass(firstMileage)" class="list-l">
                        {{ formatMileageDistance(firstMileage.distance) }} ({{
                          firstMileage.time
                        }})
                      </p>
                      <p class="list-r">{{ firstMileage.created_at }}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="btn-wrap">
                <button
                  class="btn btn-bold btn-lg btn-green"
                  type="button"
                  @click.prevent="exportMileAge"
                >
                  Export full report
                </button>
                <button
                  class="btn btn-white btn-bold btn-lg"
                  type="button"
                  @click="mileageOpen = false"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>
<script>
import CRMLayout from "./CRMLayout";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import _ from "lodash";
import { API_ENDPOINT, RIDE_SERVICE_ENDPOINT } from "@/utils/api";
import BuildReadOnly from "@/components/include/user-bikes/BuildReadOnly";
import Feed from "@/components/include/user-bikes/Feed";
import axios from "axios";

export default {
  name: "CRMInfoBuild",
  components: {
    layout: CRMLayout,
    vSelect,
    DatePicker,
    "build-read-only": BuildReadOnly,
    feed: Feed
  },
  data() {
    return {
      pageTitle: "User bike",
      userList: [],
      showBuildInfo: false,
      edit: false,
      mileageOpen: false
    };
  },
  computed: {
    ...mapGetters({
      bike: "bikes/bike",
      isRepairTab: "repairs/isRepairTab",
      mileageGroups: "bikes/mileage",
      firstMileage: "bikes/firstMileage",
      lastMileage: "bikes/lastMileage",
      repair: "repairs/repair"
    }),
    GPSAntennaTypes() {
      return [
        { code: "FTC.3D", label: "FTC.3D" },
        { code: "FTC.CNC", label: "FTC.CNC" },
        { code: "OS.3D", label: "OS.3D" },
        { code: "OS.INJ", label: "OS.INJ" }
      ];
    },
    brakeTypes() {
      return [
        { code: true, label: "Moto" },
        { code: false, label: "Regular" }
      ];
    },
    speedLimits() {
      return [
        { code: "20", label: "20" },
        { code: "24", label: "24" },
        { code: "25", label: "25" },
        { code: "30", label: "30" },
        { code: "32", label: "32" }
      ];
    },
    lastMileageData() {
      if (!this.lastMileage) {
        return "-";
      }

      return `${this.formatMileageDistance(this.lastMileage.distance)} m (${
        this.lastMileage.time
      } h)`;
    },
    latestMileageGroup() {
      return this.mileageGroupKeys.length - 1;
    },
    mileageGroupKeys() {
      return Object.keys(this.mileageGroups);
    },
    saveBtnText() {
      return this.edit ? "Save" : "Edit";
    },
    showBikeReadOnly() {
      return this.showBuildInfo && this.bike && this.bike["full_build"];
    },
    activatedDate() {
      if (this.bike && this.bike["activated_at"]) {
        return this.$moment
          .unix(this.bike["activated_at"])
          .format("DD.MM.YYYY HH:mm");
      }

      return "Non activated";
    },
    totalDistance() {
      if (this.bike) {
        return this.bike["total_distance"];
      }
      return null;
    },
    totalTime() {
      if (this.bike) {
        return this.bike["total_time"];
      }
      return null;
    },
    bikeWarranty() {
      if (this.bike) {
        return this.bike["warranty_type"] === "full" ? "Full" : "Limited";
      }
      return null;
    },
    purchaseDate() {
      if (this.bike && this.bike["purchase_date"]) {
        return this.$moment
          .unix(this.bike["purchase_date"])
          .format("DD.MM.YYYY");
      }
      return null;
    },
    buildTitle() {
      if (this.bike) {
        return this.bike.build.label;
      }
      return null;
    },
    buildLink() {
      if (this.bike) {
        return `/builds/${this.bike.build.code}`;
      }
      return null;
    },
    bikeStatuses() {
      return [
        { label: "Non activated", code: "non-active" },
        { label: "Activated", code: "active" }
      ];
    },
    bikeMainStatuses() {
      return [
        { label: "Online", code: 0 },
        { label: "Offline", code: 1 },
        { label: "Locked", code: 2 },
        { label: "Theft Mode", code: 3 }
      ];
    },
    serialNumber: {
      get() {
        if (!this.bike) {
          return null;
        }
        return this.bike["serial_number"];
      },
      set(value) {
        this.$store.commit("bikes/setSerialNumber", value);
      }
    },
    frameNumber: {
      get() {
        if (!this.bike) {
          return null;
        }
        return this.bike["frame_number"];
      },
      set(value) {
        this.$store.commit("bikes/setFrameNumber", value);
      }
    },
    manufacturingDate: {
      get() {
        if (this.bike && this.bike["manufacturing_date"]) {
          if (this.bike["manufacturing_date"].constructor.name === "Date") {
            return this.bike["manufacturing_date"];
          }
          return this.$moment.unix(this.bike["manufacturing_date"]).toDate();
        }
        return null;
      },
      set(date) {
        this.$store.commit("bikes/setManufacturingDate", date);
      }
    },
    simCardNumber: {
      get() {
        if (!this.bike) {
          return null;
        }
        return this.bike["sim_card_number"];
      },
      set(value) {
        this.$store.commit("bikes/setSimCardNumber", value);
      }
    },
    owner: {
      get() {
        if (!this.bike) {
          return null;
        }
        return this.bike.owner;
      },
      set(value) {
        value.label = value.name;
        delete value.name;
        this.$store.commit("bikes/setOwner", value);
      }
    },
    customTitle: {
      get() {
        if (!this.bike) {
          return null;
        }
        return this.bike.title;
      },
      set(value) {
        this.$store.commit("bikes/setTitle", value);
      }
    },
    status: {
      get() {
        if (!this.bike) {
          return null;
        }
        let label;
        if (this.bike.status === "active") {
          label = "Activated";
        } else {
          label = "Non activated";
        }
        return {
          label,
          code: this.bike.status
        };
      },
      set(value) {
        this.$store.commit("bikes/setStatus", value);
      }
    },
    GPSAntennaType: {
      get() {
        if (!this.bike) {
          return null;
        }
        return this.GPSAntennaTypes.find(item => {
          return item.code === this.bike["gps_antenna_type"];
        });
      },
      set(value) {
        this.$store.commit("bikes/setGPSAntennaType", value);
      }
    },
    brakeType: {
      get() {
        if (!this.bike) {
          return null;
        }
        return this.brakeTypes.find(item => {
          return item.code === this.bike["brake_type"];
        });
      },
      set(value) {
        this.$store.commit("bikes/setBrakeType", { ...value });
      }
    },
    speedLimit: {
      get() {
        if (!this.bike) {
          return null;
        }
        let speedLimit = {
          ...this.speedLimits.find(item => {
            return item.code === this.bike["speed_limit"];
          })
        };
        speedLimit.label += " K/h";
        return speedLimit;
      },
      set(value) {
        this.$store.commit("bikes/setSpeedLimit", value);
      }
    },
    main_status: {
      get() {
        if (!this.bike) {
          return null;
        }
        let label;
        switch (this.bike.main_status) {
          case 0:
            label = "Online";
            break;
          case 1:
            label = "Offline";
            break;
          case 2:
            label = "Locked";
            break;
          case 3:
            label = "Theft Mode";
            break;
          default:
            label = "Undefined";
            break;
        }
        return {
          label,
          code: this.bike.main_status
        };
      },
      set(value) {
        this.$store.commit("bikes/setMainStatus", value);
      }
    },
    showBuildInfoBtnText() {
      return this.showBuildInfo ? "Hide build info" : "Show build info";
    },
    closeOrCancel() {
      return this.edit ? "Cancel" : "Close";
    }
  },
  created() {
    this.load();
  },
  beforeRouteLeave(to, from, next) {
    this.clearBike();
    next();
  },
  destroyed() {
    this.clearBike();
  },
  methods: {
    saveRepair() {
      this.$store.dispatch("repairs/save").then(() => {
        this.$root.$emit("modalShow", {
          type: "info",
          text: "Repair saved"
        });
        this.$store.dispatch("repairs/loadLatest", this.$route.params.id);
      });
    },
    deleteRepair() {
      if (this.repair.status === "created") {
        this.$store.dispatch("repairs/delete").then(() => {
          this.$store.dispatch("repairs/loadLatest", this.$route.params.id);
        });
      } else {
        this.$store.dispatch("repairs/loadLatest", this.$route.params.id);
      }
    },
    formatMileageDistance(distance) {
      if (distance < 1000) {
        return distance;
      }

      return distance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    mileageClass(item) {
      return { red: item["rolled"] };
    },
    mileage(date) {
      return this.mileageGroups[date];
    },
    clearForm() {
      this.load();
    },
    closeOrNotEdit() {
      if (this.edit) {
        this.edit = false;
        this.clearForm();
      } else {
        this.$router.push("/bikes").catch(() => {});
      }
    },
    toggleBuildInfo() {
      this.showBuildInfo = !this.showBuildInfo;
    },
    clearBike() {
      this.$store.commit("bikes/setBike", null);
    },
    saveOrEdit() {
      if (!this.edit) {
        this.edit = true;
      } else {
        this.$store
          .dispatch("orderItem/checkFrameNumberAvailability", {
            build: {
              id: this.bike.build.code
            },
            frame_number: this.frameNumber,
            order_id: this.bike.order_id
          })
          .then(() => {
            this.$store
              .dispatch("bikes/save", this.$route.params.id)
              .then(() => {
                this.load();
                this.edit = false;
                this.$root.$emit("modalShow", {
                  type: "info",
                  text: "Bike saved"
                });
              })
              .catch(error => {
                console.log(error);
                let message = "Error while save bike. Try later.";
                if (error.message) {
                  message = error.message;
                }
                this.$root.$emit("modalShow", {
                  type: "info",
                  text: message
                });
              });
          })
          .catch(error => {
            this.$root.$emit("modalShow", {
              type: "info",
              text: error || "Error while check bike frame number. Try later."
            });
          });
      }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUser(loading, search, this);
      }
    },
    searchUser: _.debounce((loading, search, vm) => {
      const url = `${API_ENDPOINT}v2/users/suggest/email`;
      vm.$http
        .post(url, { query: search })
        .then(result => {
          vm.userList = result.data;
        })
        .finally(() => {
          loading(false);
        });
    }, 350),
    loadFeed(bikeID) {
      this.$store.dispatch("orderFeed/load", {
        type: "bikes",
        subject_id: bikeID
      });
    },
    loadMileage(bikeID) {
      this.$store.dispatch("bikes/loadMileage", {
        bike_id: bikeID
      });
    },
    exportMileAge() {
      axios
        .post(RIDE_SERVICE_ENDPOINT + "mileage/export", {
          bike_id: this.$route.params.id
        })
        .then(response => {
          location.href = response.data.data.url;
        });
    },
    load() {
      const bikeID = this.$route.params.id;
      if (!bikeID) {
        this.$router.push("/").catch(() => {});
      } else {
        this.$store
          .dispatch("bikes/load", { bikeID, withFullBuild: true })
          .then(() => {
            this.loadFeed(bikeID);
            // this.loadMileage(bikeID);
            let status;
            switch (this.bike.status) {
              case "non-active":
                status = { label: "Non activated", code: "non-active" };
                break;
              case "active":
                status = { label: "Activated", code: "active" };
                break;
              default:
                status = { label: "Non activated", code: "non-active" };
                break;
            }
            this.status = status;
          })
          .catch(error => {
            console.log(error);
            this.$root.$emit("modalShow", { text: error, type: "error" });
          });
      }
    }
  }
};
</script>

<style scoped>
.btn-big span {
  width: 100%;
  text-align: center;
}

.btn-big span i {
  transform: translate(0, 0);
}

.modalMileage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  overflow: auto;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
}

.modalMileage * {
  box-sizing: border-box;
}

.modalMileage .modal-container {
  background: #ffffff;
  box-shadow: 0px 0px 2px #454545;
  border-radius: 5px;
  width: 100%;
  max-width: 800px;
  padding: 10px 10px 40px;
  margin: auto;
}

.modalMileage .close {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.modalMileage .title {
  font-size: 20px;
  line-height: 1.2;
  padding: 18px 0;
  margin: 0;
  display: block;
  width: 100%;
  text-align: center;
}

.modalMileage .month-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  text-align: right;
  color: #9a9a9a;
  margin: 0;
  display: block;
  width: 100%;
  padding: 12px 0px;
}

.modalMileage .list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

.modalMileage .list li {
  display: flex;
  align-items: center;
  max-width: 420px;
  margin: 0 auto 8px;
}

.modalMileage .list li .list-l {
  width: 50%;
  text-align: right;
  padding-right: 10px;
  font-size: 14px;
  line-height: 1.2;
  color: #454545;
  box-sizing: border-box;
}

.modalMileage .list li .list-r {
  width: 50%;
  padding-left: 10px;
  font-size: 14px;
  line-height: 1.2;
  color: #454545;
  margin-left: auto;
  box-sizing: border-box;
}

.modalMileage .red {
  color: #bf0000 !important;
  font-weight: bold;
}

.modalMileage .wrapper-month {
  max-width: 580px;
  width: 100%;
  margin: 0 auto 56px;
  padding: 0;
}

.modalMileage .btn-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.modalMileage .btn-wrap .btn {
  margin: 10px;
  width: 220px;
}

.link {
  font-weight: normal;
}
</style>
<style lang="scss">
.mb-32 {
  margin-bottom: 32px !important;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 16px;
}

.mb-4 {
  margin-bottom: 24px;
}

.show-btn {
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  display: table;
  margin: 0 auto 36px;
  align-items: center;
  color: #1c1c1c;
  border: none;
  border-bottom: 1px dashed;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
}

.export-btn {
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #1c1c1c;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.serial__wrapper {
  .text-right {
    padding: 0 50px;
  }

  .show-btn {
    font-size: 14px;
    margin: 0 auto;
  }
}

.tab-btn {
  outline: none;
  border: none;

  &.active {
    background-color: #fff;
    color: #454545;

    &:first-of-type:before {
      left: 0;
      bottom: -5px;
    }

    &:last-of-type:after {
      z-index: -1;
      content: "";
      background-color: #f1f1f1;
      position: absolute;
      bottom: 0;
      left: 100%;
      width: 10px;
      height: 50%;
      border-bottom-left-radius: 5px;
    }

    &:before {
      z-index: -1;
      content: "";
      background-color: #fff;
      position: absolute;
      bottom: 0;
      left: -5px;
      width: calc(100% + 10px);
      height: 50%;
    }
  }
}

.main__content {
  hr {
    &.w-pr {
      max-width: 700px;
      left: -47px;
      margin: 20px auto 15px;
      @media (max-width: 1800px) {
        left: -20px;
      }
    }
  }

  .color__black {
    color: #000;
  }
}
</style>
