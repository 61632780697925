import { render, staticRenderFns } from "./CRMOrder.vue?vue&type=template&id=172e637d&scoped=true"
import script from "./CRMOrder.vue?vue&type=script&lang=js"
export * from "./CRMOrder.vue?vue&type=script&lang=js"
import style0 from "./CRMOrder.vue?vue&type=style&index=0&id=172e637d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "172e637d",
  null
  
)

export default component.exports