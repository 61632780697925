import { render, staticRenderFns } from "./CRMAdmin.vue?vue&type=template&id=b2a34692&scoped=true"
import script from "./CRMAdmin.vue?vue&type=script&lang=js"
export * from "./CRMAdmin.vue?vue&type=script&lang=js"
import style0 from "./CRMAdmin.vue?vue&type=style&index=0&id=b2a34692&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2a34692",
  null
  
)

export default component.exports